.or-input-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.or-input-form:not(:last-child) {
  margin-bottom: 1.5rem;
}

.or-input-form .separator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.or-input-form .separator span {
  background-color: var(--background-contrast-grey);
  border-radius: 4px;
  padding: 5px 10px;
  margin: 10px;
}

.or-input-form .separator:after,
.or-input-form .separator:before {
  background-color: var(--background-contrast-grey);
  height: 20px;
  width: 2px;
  border-radius: 5px;
  content: '';
  display: block;
}

@media (min-width: 1px) and (max-width: 992px) {
  .or-input-form {
    flex-direction: column;
    align-items: flex-start;
  }
  .or-input-form .separator {
    flex-direction: row;
    width: 100%;
  }
  .or-input-form .separator:after,
  .or-input-form .separator:before {
    height: 2px;
    width: 20px;
  }
}
