.rt-tr .selected {
  background: rgba(0, 0, 0, 0.08);
}

.datapass-message-icon {
  position: relative;
  display: inline-block;
}

.red-dot {
  height: 8px;
  width: 8px;
  background-color: var(--background-action-high-red-marianne);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 0;
}
