.stat_card {
  display: flex;
  flex-direction: column;
}

.stat_card_head {
  flex-basis: 0;
  flex-grow: 1;
}

.stat_card_number {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 80px;
  line-height: 80px;
  color: #1a535c;
  padding: 0 0 50px 0;
  margin: auto;
}

.stat_card_graph {
  min-height: 200px;
  margin: auto;
  width: 100%;
}
