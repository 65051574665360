.list-header-container {
  width: 100%;
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  max-width: 1200px;
  width: 100%;
}

@media all and (max-width: 1248px) {
  .list-header {
    margin: 1.5rem;
    width: calc(100% - 3rem);
  }
}
