.data-provider-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 0.5rem;
  background-color: white;
  width: 100%;
  text-decoration: none;
  border: none;
  border-radius: 0.28571429rem;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.data-provider-card > .data-provider-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin: 0 0.5rem;
  height: auto;
  flex-shrink: 0;
}

.data-provider-card > .data-provider-logo > img {
  width: 100%;
}

.data-provider-card > .data-provider-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
  margin-left: 20px;
}

.data-provider-card > .data-provider-cta {
  flex-shrink: 0;
}

@media all and (max-width: 768px) {
  .data-provider-card {
    flex-direction: column;
  }

  .data-provider-card > .data-provider-pic {
    width: 40px;
  }

  .data-provider-card > .data-provider-content {
    text-align: center;
    margin: 15px 10px;
  }
}
