.datapass-table {
  border-collapse: collapse;
  width: 100%;
  color: var(--text-default-grey);
  background-color: white;
  font-size: 0.9rem;
}

.datapass-table-wrapper {
  position: relative;
}

.datapass-table-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  animation: datapass-table-fadein 0.3s;
}

@keyframes datapass-table-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.datapass-table tbody {
  position: relative;
}

.datapass-table-placeholder {
  position: absolute;
  top: 70%;
  text-align: center;
  left: 50%;
  padding: 10px 20px;
  color: var(--text-mention-grey);
  background-color: white;
  transform: translate(-50%, -50%);
}

.datapass-table .header-container {
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.datapass-checkbox-filter.fr-checkbox-group input[type='checkbox'] + label {
  padding: 0.4rem 0;
}

.datapass-checkbox-filter.fr-checkbox-group
  input[type='checkbox']
  + label:before {
  margin: 0.4rem 0;
}

.datapass-table .fr-label + .fr-input,
.datapass-table .fr-label + .fr-input-wrap,
.datapass-table .fr-label + .fr-select {
  margin-top: 0;
}

.datapass-table-sorting-arrow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.4rem;
}

.datapass-table-column-header {
  display: flex;
}

.datapass-table .sorting-header {
  cursor: pointer;
}

.datapass-table .selected {
  background: rgba(0, 0, 0, 0.08);
}

.datapass-table .new {
  font-weight: bold;
}

.datapass-table th,
td {
  padding: 15px;
}

.datapass-table th {
  padding: 15px 15px 15px 15px;
  position: relative;
  font-weight: 600;
}

.datapass-table-column-title {
  position: absolute;
  top: 10px;
  text-transform: uppercase;
}

.datapass-table.firstColumnFixed th:first-of-type,
.datapass-table.firstColumnFixed td:first-of-type {
  position: sticky;
  left: 0;
  z-index: 1;
}

.datapass-table.firstColumnFixed th:first-of-type {
  background-color: var(--text-action-high-blue-france);
}

.datapass-table.firstColumnFixed td:first-of-type {
  background-color: white;
}

.datapass-table input {
  background-color: white;
}

.datapass-table thead {
  padding: 10px 0;
  color: white;
  background-color: var(--text-action-high-blue-france);
  position: relative;
}

.datapass-table tbody tr {
  height: 77px;
  border-bottom: 1px solid #e5e5e5;
}

.datapass-table .clickable-row:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
}
