/* side by side input field */
.form-row {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.form-row:not(:last-child) {
  margin-bottom: 1.5rem;
}

.form-col {
  flex-grow: 1;
  width: 50%;
}

.form-col + .form-col {
  margin-left: 2em;
}

@media all and (max-width: 576px) {
  .form-row {
    flex-direction: column;
  }

  .form-col {
    width: inherit;
  }

  .form-col + .form-col {
    margin-left: inherit;
    margin-top: 2em;
  }
}
