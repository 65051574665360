.multiselect-dropdown-button {
  cursor: pointer;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0 7px;
  font-weight: 400;
  height: 2.5rem;
  line-height: 1.5rem;
  box-shadow: inset 0 -2px 0 0 var(--border-plain-grey);
  /* Source: https://material.io/tools/icons/?icon=arrow_drop_down&style=baseline */
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>')
    no-repeat top 0.55em right 0;
  text-align: start;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-options-left .datapass-dropdown-content {
  right: auto;
}
