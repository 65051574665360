.datapass_modal_backdrop {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.datapass_modal_body {
  max-width: 50em;
  max-height: 90vh;
  overflow-y: auto;
}
