.enrollment-card {
  display: flex;
  flex-direction: column;
  flex: none !important;
  width: 30%;
}

.enrollment-card.large {
  width: 45%;
}

.enrollment-card.large .enrollment-card-body {
  display: flex;
}

.enrollment-card.large .enrollment-card-body .enrollment-card-image {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enrollment-card.large .enrollment-card-body .enrollment-card-content {
  width: 66%;
  margin-left: 20px;
}

.enrollment-card-image img {
  max-width: 90%;
  max-height: 90%;
}

.enrollment-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.enrollment-card-header {
  display: flex;
  gap: 1rem;
}

.enrollment-card-subtitle {
  font-size: small;
  font-style: italic;
  color: var(--text-mention-grey);
}

.enrollment-card-title {
  font-size: large;
  font-weight: bolder;
}

.enrollment-card-description {
  margin-bottom: 0.5rem;
}

.enrollment-card-actions {
  display: flex;
  gap: 15px;
}

.enrollment-card-footer {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 5px solid #000091;
  background-color: #f6f6f6;
  padding: 20px 15px;
  display: flex;
}

.enrollment-card-footer-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.enrollment-card-footer-details-title {
  font-weight: 600;
  color: var(--text-action-high-blue-france);
}

.enrollment-card-footer-details-button {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: #666666;
}

.enrollment-card-footer-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 992px) {
  .enrollment-card {
    width: 45%;
  }
}
