.fr-container--fluid {
  overflow-x: hidden;
}

.left-panel-background {
  background-color: var(--background-alt-grey);
  height: 100%;
}

.left-panel {
  text-align: center;
}

.panel {
  text-align: left;
}

.panel > .login-button {
  display: flex;
  align-items: left;
  justify-content: center;
  margin: 1.5rem auto;
}

.next-steps-center {
  display: flex;
  align-items: center;
}

.next-steps,
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 30px 0 20px;
  font-size: 14px;
}

.timeline {
  margin: 0 0 50px;
  align-items: center;
}

.next-steps > div,
.timeline > div {
  width: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.timeline > div {
  width: 25%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.timeline > div > .time-indication {
  font-style: italic;
  position: absolute;
  width: 90px;
  right: -45px;
  background-color: var(--background-default-grey);
  z-index: 5;
  padding: 0 5px;
  color: var(--text-action-high-blue-france);
}

.timeline > div:before,
.timeline > div:after {
  content: '';
  width: 50%;
  border: 1px dashed var(--text-action-high-blue-france);
  opacity: 0.75;
}

.timeline > div:first-of-type:before,
.timeline > div:last-of-type:after {
  opacity: 0;
}

.timeline .step {
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background-color: var(--text-action-high-blue-france);
}

.next-steps svg {
  fill: var(--text-action-high-blue-france);
}

.next-steps .prepare > ul {
  padding: 10px;
  margin: 0;
}

.next-steps-center{
  display: block;
}

.new-login-container {
  flex-direction: column;
  border: 1px solid var(--datapass-light-grey);
  padding: 16px 24px 8px 24px
}

.new-login-container p {
  font-weight: bold;
}

@media all and (max-width: 992px) {
  .new-login-container {
    padding: 0 5rem;
  }
}

@media all and (max-width: 768px) {
  .new-login-container {
    padding: 0;
  }
}
