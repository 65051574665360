:root {
  --datapass-background-grey: #ebeff3;
  --datapass-dark-grey: #3a3a3a;
  --datapass-light-grey: #e5e5e5;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}

.page {
  min-height: 100vh;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'main'
    'footer';
}

.full-page {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 20%;
}

.dark-background {
  background-color: var(--blue-france-975-75);
}

@media all and (max-width: 1248px) {
  .full-page {
    padding: 1.5rem 5%;
  }
}

.table-container {
  margin: 0 1.5rem 1.5rem;
}

.page-container {
  max-width: 1200px;
  width: 100%;
  margin: 1.5rem auto 1.5rem;
}

.highlighted {
  padding: 10px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  border-radius: 5px;
  background-color: var(--blue-france-975-75)!important;
}

@media all and (max-width: 1248px) {
  .page-container {
    margin: 1.5rem;
    width: calc(100% - 3rem);
  }
}

.list-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  background-color: var(--datapass-background-grey);
}

.list-page.list-page-white {
  background-color: white;
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.list-title {
  margin: 0;
  width: 100%;
}
