.datapass_card_container {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: flex-start;
  align-content: stretch;
}

.datapass_card_container .datapass_card {
  flex: 1;
}

.datapass_card_container .datapass_card.enrollments-card {
  flex: 2;
}

.datapass_card_container + .datapass_card_container {
  margin-top: 1.5em;
}

.datapass_card_container_grid {
  display: grid;
  grid-auto-columns: minmax(100px, 200px);
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 1.5em;
}

.datapass_card {
  border: 1px solid #dddddd;
  padding: 1.5em;
  background-color: white;
}

.datapass_card.datapass_add_card {
  border: 2px dashed var(--border-default-grey);
  min-height: 500px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  line-height: 25px;
  color: var(--text-mention-grey);
}

.datapass_card.datapass_add_card:hover {
  background-color: #fafafa;
}

.datapass_card_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
