.activity-head {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.event-item {
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0;
}

.event-item:first-child {
  padding-top: 0;
}

.event-item:last-child {
  padding-bottom: 0;
}

.event-icon {
  transform: scale(0.8);
}

.event-item {
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0;
}

.event-content {
  flex: 1 100%;
  margin-left: 0.2rem;
}

.event-content .fr-hint-text {
  font-style: italic;
}

.event-head {
  display: flex;
  justify-content: space-between;
}

.event-comment {
  background-color: var(--background-contrast-grey);
  color: var(--text-label-grey);
  border-radius: 0.25rem;
  margin-top: 0.4rem;
  white-space: pre-line;
  padding: 1rem;
}

.event-comment-demandeurs {
  background-color: var(--background-contrast-info);
}
