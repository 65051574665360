.no-enrollments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 80px 0;
}

.no-enrollments-container p {
    margin: 0;
    text-align: center;
}
